<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo align-items-center">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1 mb-0">
          Hệ thống dịch vụ công và một cửa điện tử
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto login-tabs"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Hệ thống dịch vụ công và một cửa điện tử
          </b-card-title>
          <b-card no-body>
            <b-tabs>
              <b-tab
                title="Tài khoản"
                active
              >
                <validation-observer
                  ref="loginForm"
                  #default="{invalid}"
                >
                  <b-form
                    class="auth-login-form"
                    @submit.prevent="login"
                  >
                    <!-- email -->
                    <b-form-group
                      label="Tài khoản nội bộ"
                      label-for="login-username"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Username"
                        vid="text"
                        rules="required"
                      >
                        <b-form-input
                          id="login-username"
                          v-model="username"
                          :state="errors.length > 0 ? false:null"
                          name="login-username"
                          placeholder="Nhập tài khoản"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- forgot password -->
                    <b-form-group>
                      <div class="d-flex justify-content-between">
                        <label for="login-password">Mật khẩu</label>
                      </div>
                      <validation-provider
                        #default="{ errors }"
                        name="Password"
                        vid="password"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        >
                          <b-form-input
                            id="login-password"
                            v-model="password"
                            type="password"
                            :state="errors.length > 0 ? false:null"
                            class="form-control-merge"
                            name="login-password"
                            placeholder="Nhập mật khẩu"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- submit buttons -->
                    <b-button
                      type="submit"
                      variant="primary"
                      block
                      :disabled="invalid"
                    >
                      Đăng nhập
                    </b-button>
                  </b-form>
                </validation-observer>
              </b-tab>
              <b-tab title="USB ký số">
                <div class="tab-ky-so">
                  <p class="mb-3 mt-3 text-center">
                    Tải về công cụ ký điện tử trên <br>
                    Windows & MacOs
                    <a
                      class="download-text"
                      href=""
                      @click="xuatExcel"
                    >tại đây</a>
                  </p>
                  <b-button
                    variant="primary"
                    block
                    @click="excuteSign()"
                  >
                    Đăng nhập
                  </b-button>
                </div>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroup, BCardTitle, BImg, BForm, BButton, VBTooltip, BTabs, BTab, BCard,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import END_POINTS from '@/api/endpoints'
import { vgca_sign_msg } from '@/utils/vgcaplugin'
import { load } from 'recaptcha-v3'

let recaptcha1
export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BTabs,
    BTab,
    BCard,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      username: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
      captchaToken: '',
      siteKey: '6LcZdBwlAAAAABkS1Y-dJrgJwqJrRrsBfZ5ngIpd',
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    this.loadCaptCha()
  },
  destroyed() {
    recaptcha1.hideBadge()
  },
  methods: {
    async loadCaptCha() {
      load(this.siteKey).then(recaptcha => {
        recaptcha.execute('').then(token => {
          this.captchaToken = token
          recaptcha1 = recaptcha
        })
      })
    },
    errorLoginToast() {
      this.$toasted.clear()
      this.$toasted.global.showErrorMessage({
        message: 'Đăng nhập thất bại!',
      })
    },
    laySoLuongHoSo() {
      this.$axios.get(END_POINTS.COMMON.LAY_SO_LUONG_HO_SO, false).then(response => {
        if (response.data?.succeeded) {
          this.$store.commit('common/SET_DOC_NUMBER', response.data.data)
        }
      })
    },
    layNavigations() {
      this.$axios.get(END_POINTS.CHUNG.NAV_TREE, false).then(response => {
        if (response.data && response.data.code === 200) {
          this.$store.commit('common/SET_NAV_TREE', response.data.data)
        }
      })
    },
    async login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          const payload = {
            userName: this.username.trim(),
            password: this.password.trim(),
            captchaToken: this.captchaToken,
          }
          if (this.username && this.password) {
            this.$store.dispatch('common/login', payload).then(res => {
              if (res.succeeded) {
                this.$router.push({
                  path: '/trang-chu',
                }).catch(err => err)
                this.laySoLuongHoSo()
                this.layNavigations()
              }
            })
          }
        }
      })
    },
    xuatExcel() {},
    excuteSign(sender = '_Sign') {
      const SignCallBack = async (sender1, rv) => {
        const received = JSON.parse(rv)
        if (received.Status === 0) {
          this.$store.dispatch('common/loginUSB', {
            signature: received.Signature,
          }).then(res => {
            if (res.succeeded) {
              this.laySoLuongHoSo()
              this.layNavigations()
            }
          })
        }
      }

      const crypto = require('crypto')
      // hash from file hashed joim text hashed
      const hash = crypto.createHash('sha256').update('1').digest('base64')

      const hashValue = hash
      const hashAlg = 'SHA256'

      const prms = {}
      prms.HashValue = hashValue
      prms.HashAlg = hashAlg

      const jsonPrms = JSON.stringify(prms)
      vgca_sign_msg(sender, jsonPrms, SignCallBack)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.download-text {
  color: #005bd4;
}

.tab-ky-so {
  padding: 0 15px 30px 15px;
}
</style>
